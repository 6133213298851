.footer {
  width: 100%;
  .ue-logos {
    width: 60%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    img {
      max-width: 100%;
      max-height: 12rem;
    }
  }
}

@media only screen and (max-width: 768px) {
  .footer {
    .ue-logos {
      width: 90%;
    }
  }
}