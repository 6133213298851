.navbar-container {
  .logo {
    padding-top: 10rem;
    text-align: center;
    img {
      max-width: 25rem;
      margin-bottom: 2rem;
    }
  }
  .navbar {
    width: 100%;
    padding: 1rem;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    .nav-item {
      font-size: 20px;
      color: #3b3b3b;
      padding: 0.5rem;
      text-align: center;
      margin: 0.5rem;
      cursor: pointer;
      text-decoration: none;
      user-select: none;
      font-weight: 500;
      transition: color 0.2s;
      &:hover {
        color: #000;
      }

      &.active {
        color: #000;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .navbar {
    flex-direction: column;
    text-align: center;
  }

  .navbar-container {
    .logo {
      img {
        max-width: 70%;
      }
    }
  }
}