.layout {
  width: 100%;
  display: flex;
  flex-direction: column;
  .content {
    padding: 2rem;
    min-height: 20rem;
    max-width: 75rem;
    margin-left: auto;
    margin-right: auto;
    font-size: 19px;
    width: 100%;
    * {
      color: #3b3b3b;
      line-height: 170%;
    }
    h1,
    h2,
    h3 {
      color: #000;
    }
  }
}
