.contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .email,
  .phone {
    font-size: 1.25rem;
  }
  .email {
    padding: 2rem;
  }
  .phone {
    .text-bold {
      font-weight: 600;
    }
  }
}
